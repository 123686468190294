<template>
    <auth pattern="blue">
        <header class="mb-5">
            <h1 class="fw-bold display-4 text-center mb-4">
                {{ $t('Správny vývoj') }} <br>
                {{ $t('a manipulácia') }} <br>
                {{ $t('s bábätkom') }}
            </h1>
            <h2 class="fw-bold h1 text-center mb-4" style="color: #ff7070">Monika Majorošová</h2>
            <h3 class="fw-bold text-center text-white m-0">{{ $t('Inštruktorka psychomotorického') }}<br>{{ $t('vývoja dieťatka') }}</h3>
        </header>
        <h1 class="fw-bold mb-3">
            {{ $t('Prihlásenie') }}
        </h1>
        <form @submit.prevent="submitForm">
            <div class="form-floating mb-2">
                <input id="email" v-model="input.email.value" :class="'form-control ' + isValidInput(input.email)" placeholder="" type="email">
                <label for="email">{{ $t('E-mail') }}</label>
            </div>
            <div class="form-floating mb-3">
                <input id="password" v-model="input.password.value" :class="'form-control ' + isValidInput(input.password)" placeholder="" type="password">
                <label for="password">{{ $t('Heslo') }}</label>
            </div>
            <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                <submit-button :disabled="!canSubmit || fetching" :fetching="fetching" :title="submitButtonTitle"></submit-button>
                <router-link :to="{name: 'forgot-password'}" class="fw-bold btn btn-danger mt-2 mt-md-0 px-3">
                    {{ $t('Zabudnuté heslo') }}
                </router-link>
            </div>
        </form>
    </auth>
</template>

<script>
import v8n from 'v8n'
import SubmitButton from '../component/SubmitButton'
import Auth from '../layout/AuthLayout'
import { canSubmitForm } from '@/util/canSubmitForm'
import { EMAIL_REGEX, PASSWORD_REGEX } from '@/util/regex'
import { validInput } from '@/util/validInput'
import { formInput } from '@/util/formInput'
import { prepareData } from '@/util/prepareData'
import { watchInputChange } from '@/util/watchInputChange'

export default {
    components: { Auth, SubmitButton },
    computed: {
        canSubmit () {
            return canSubmitForm(this.input)
        },
        fetching () {
            return this.$store.getters['auth/isFetching']
        },
        submitButtonTitle () {
            return this.$t('Prihlásiť')
        },
    },
    created () {
        watchInputChange(this.input, this)
    },
    data () {
        return {
            input: {
                email: formInput({
                    validation: value => v8n()
                        .pattern(EMAIL_REGEX)
                        .test(value),
                }),
                password: formInput({
                    validation: value => v8n()
                        .pattern(PASSWORD_REGEX)
                        .test(value),
                }),
            },
        }
    },
    methods: {
        isValidInput (input) {
            return validInput(input)
        },
        submitForm () {
            if (! this.canSubmit) return
            
            this.$store.dispatch('auth/login', prepareData(this.input))
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/resource/scss/breakpoints';

.btn {
    @include media-breakpoint-down(sm) {
        min-width: 100% !important;
    }
}
</style>
