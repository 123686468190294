import { equals, forEach, is, not, values } from 'ramda'

/**
 * Watch for input change.
 *
 * @param {Object} inputs
 * @param {Object} instance
 * @param {boolean} edit
 * @return void
 */
export const watchInputChange = (inputs, instance, edit = false) => {
    forEach(input => {
        /*const input = inputs[name]
        
        // console.log('inputs', inputs)
        
        console.log(name, JSON.stringify(input), input.valid, input.validation)
        console.log(instance.$watch)
        
        instance.$watch(() => input?.value, value => {
            // console.log('here', name, value)
            
            const oldValue = input?.oldValue
            
            input.dirty = edit ? (is(Array, value) ? not(equals(value, oldValue)) : value !== oldValue) : true
            
            if (input.validation) {
                input.valid = input.validation(value)
            }
        })*/
        
        /*instance.$watch(name, value => {
            console.log('value change', name, value)
        })*/
        
        // console.log('input', name, input, instance.$watch)
        
        /*instance.$watch(
        
        )*/
        
        instance.$watch(
            () => {
                if (! input?.value) {
                    watchInputChange(input, instance, edit)
                }
                
                return input?.value
            },
            value => {
                const oldValue = input?.oldValue
                
                input.dirty = edit ? (is(Array, value) ? not(equals(value, oldValue)) : value !== oldValue) : true
                input.valid = input.validation(value)
            },
        )
    }, values(inputs))
}
